import { useCallback, useMemo } from 'react';
import { CARD_ACTION, CARD_CONTENT, CARD_CONTENT_KEYS, CARD_WRAPPER } from '../constants';
import { useHasCardV2 } from './useHasCardV2';
import { INVALID_SECTION_SETTING, getFormattedSectionSettings } from '../../api/utils';
import { QUICK_ACTIONS_SECTION_KEY } from '../actions/container/types';
import { PREVIEW_SECTION_KEY } from '../actions/preview/types';
import { useHasQuickActions } from './useHasQuickActions';
/**
 * card section adapter gets all the settings and decides what should be displayed
 * every section is responsible for its own data
 * @returns three arrays of CardSection cardContentSections, cardWrapperSections and cardActionSections to be displayed in card component
 */
const useCardSectionsAdapter = ({
  registry,
  registryLoading,
  cardSectionSettings
}) => {
  const hasCardV2 = useHasCardV2();
  const hasQuickActions = useHasQuickActions();
  const data = useMemo(() => getFormattedSectionSettings(cardSectionSettings), [cardSectionSettings]);
  const makeSection = useCallback((sectionKey, settings) => {
    const requiredProperties = registry.getRequiredProperties(sectionKey, settings.settings);
    return {
      sectionKey,
      settings: settings.settings,
      Component: registry.getSectionComponent(settings.sectionKey),
      PreviewComponent: registry.getSectionPreviewComponent(settings.sectionKey),
      requiredProperties
    };
  }, [registry]);
  const cardContentSections = useMemo(() => data && !registryLoading ? data.filter(settings => CARD_CONTENT_KEYS.some(x => x === settings.sectionKey)).map(settings => {
    return makeSection(settings.sectionKey, settings);
  })
  // filter out any sections other than contents
  .filter(({
    sectionKey
  }) => registry.getSectionType(sectionKey) === CARD_CONTENT)
  // filter out any section that have invalid settings, BE returns {} for valid empty setting cases.
  .filter(c => c.settings !== INVALID_SECTION_SETTING)
  // filter out any sections that don't have a component
  .filter(c => c.Component) : [], [data, registry, makeSection, registryLoading]);

  // Return wrapper sections separately, as they are always rendered
  const cardWrapperSections = useMemo(() => hasCardV2 && !registryLoading ? registry.getSectionKeys().filter(sectionKey => {
    return registry.getSectionType(sectionKey) === CARD_WRAPPER;
  }).map(sectionKey => {
    const requiredProperties = registry.getRequiredProperties(sectionKey, undefined);
    return {
      sectionKey,
      Component: registry.getSectionComponent(sectionKey),
      requiredProperties,
      PreviewComponent: registry.getSectionPreviewComponent(sectionKey)
    }; // same as above, cast to keep keys and types in check
  }).filter(c => c.Component) // filter out any sections that don't have a component
  : [], [hasCardV2, registry, registryLoading]);
  const quickActionSections = useMemo(() => {
    const quickActionSettings = data === null || data === void 0 ? void 0 : data.find(x => x.sectionKey === QUICK_ACTIONS_SECTION_KEY);
    if (quickActionSettings && hasQuickActions) {
      return quickActionSettings.settings.quickActions.sort((a, b) => a.displayOrder - b.displayOrder).slice(0, 4); //only allow 4 quick actions
    }
    return [{
      type: PREVIEW_SECTION_KEY
    }];
  }, [data, hasQuickActions]);

  // Return action sections (for now always display preview regardless of settings)
  const cardActionSections = useMemo(() => hasCardV2 && !registryLoading && quickActionSections ? quickActionSections.filter(({
    type
  }) => {
    return registry.getSectionType(type) === CARD_ACTION;
  }).map(({
    type
  }) => {
    return {
      sectionKey: type,
      Component: registry.getSectionComponent(type),
      PreviewComponent: registry.getSectionPreviewComponent(type)
    }; // same as above, cast to keep keys and types in check
  }).filter(c => c.Component) // filter out any sections that don't have a component
  : [], [hasCardV2, registry, registryLoading, quickActionSections]);
  return {
    cardContentSections,
    cardWrapperSections,
    cardActionSections
  };
};
export default useCardSectionsAdapter;