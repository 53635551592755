import { CrmSearchFLPError } from './CrmSearchFLPError';
import { CrmSearchQueryStringTooLongError } from './CrmSearchQueryStringTooLongError';
import { CrmSearchUnknownError } from './CrmSearchUnknownError';
import { CrmSearchSortingOnFieldRestrictedError } from './CrmSearchSortingOnFieldRestrictedError';
import { extractStatusAndMessageFromRawResponse } from './extractStatusAndMessageFromRawResponse';
import { BaseCrmSearchError } from './BaseCrmSearchError';
import { CrmSearchCachingQueryTimeoutError } from './CrmSearchCachingQueryTimeoutError';
import { CrmSearch403Error } from './CrmSearch403Error';
import { CrmSearchStatus0Error } from './CrmSearchStatus0Error';
import { Metrics } from '../../Metrics';
import { CrmSearch401Error } from './CrmSearch401Error';
export const createMaybeCrmSearchError = ({
  objectTypeId,
  rawData,
  rawError,
  crmSearchQueryObject,
  operationName = 'CrmIndexSearchQuery'
}) => {
  const maybeStatusAndMessage = extractStatusAndMessageFromRawResponse({
    rawData,
    rawError
  });
  if (!maybeStatusAndMessage) {
    return null;
  }
  const {
    message,
    status
  } = maybeStatusAndMessage;
  const error = new BaseCrmSearchError({
    message,
    status,
    objectTypeId,
    metadata: {
      rawError,
      rawData,
      crmSearchQueryObject
    }
  });
  if (status === 408 || status === 0) {
    Metrics.counter('handled-error', {
      status: status.toString(),
      operationName
    }).increment();
  }
  return CrmSearchCachingQueryTimeoutError.tryFromError(error) || CrmSearchFLPError.tryFromError(error) || CrmSearchQueryStringTooLongError.tryFromError(error) || CrmSearchSortingOnFieldRestrictedError.tryFromError(error) || CrmSearch401Error.tryFromError(error) || CrmSearch403Error.tryFromError(error) || CrmSearchStatus0Error.tryFromError(error) || new CrmSearchUnknownError(error);
};
export const createMaybeBoardCrmSearchError = ({
  objectTypeId,
  rawData,
  rawError,
  crmSearchQueryObject
}) => {
  const rawDataAsCrmSearch = rawData && rawData.crmObjectsByPipelineStageSearch ? {
    crmObjectsSearch: {
      validationErrors: rawData.crmObjectsByPipelineStageSearch.flatMap(stageResults => stageResults.crmSearchPayload && stageResults.crmSearchPayload.validationErrors ? stageResults.crmSearchPayload.validationErrors : [])
    }
  } : undefined;
  return createMaybeCrmSearchError({
    objectTypeId,
    rawData: rawDataAsCrmSearch,
    rawError,
    crmSearchQueryObject,
    operationName: 'CRM_OBJECT_BOARD_CRM_SEARCH_QUERY'
  });
};
export { BaseCrmSearchError, CrmSearchFLPError, CrmSearchQueryStringTooLongError, CrmSearchUnknownError, CrmSearchCachingQueryTimeoutError, CrmSearch403Error, CrmSearchStatus0Error };