import { BaseCrmSearchError } from './BaseCrmSearchError';
export class CrmSearch401Error extends BaseCrmSearchError {
  constructor(error) {
    super(error);
    this.name = 'CrmSearch401Error';
  }
  static tryFromError(error) {
    if (error.status !== 401) {
      return null;
    }
    return new CrmSearch401Error(error);
  }
}